//
// Footer Styles
// --------------------------------------------------
.sticky-badge {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	position: fixed;
	bottom: 10%;
	right: 2.5%;
	z-index: 100;
	border-radius: 50%;
	background-color: #181715;
	color: white;
	padding: 10px;
	width: 160px;
	height: 160px;

	//temp
	display: none;
	visibility: hidden;

	&:hover {
		color: white !important;

		.fondue {
			width: 105px;
		}
	}

	@media (max-width:991px) {
		bottom: 24%;
	}

	.fondue {
		width: 95px;
		height: auto;
		margin-bottom: 5px;
		margin-top: -80px;
		transition: all .2s linear;
	}

	.logo {
		width: 105px;
		height: auto;
		margin-bottom: 6px;
	}

	p {
		font-size: 11px;
		line-height: normal;
		font-weight: 500;
	}
}
#footer {
	background-color: transparent;
	color: $color-text;
	font-size: 1rem;
	margin-top: 140px;

	.signature {
		align-self: flex-end;
	}

	#newsletter {
		h4 {
			color: black;
		}

		.inf-main_bd0577f200e93dca13d0ceaf3d614ea0 {
			background-color: #fffcf1;
		}
		input {
			color: #555555;

			&::placeholder {
				color: #999999;
			}

			&[type="submit"]{
				color: white;
				border: solid 2px black !important;
				background-color: black;
				padding: 7px 14px;
				font-size: 18px;
				font-weight: 500;

				&:hover {
					border: solid 2px black !important;
					color: black;
					background-color: transparent;
				}
			} 
		}
	}

	#footer-top {
		padding: 90px 0;

		.container {
			display: flex;
			align-items: flex-start;

			.logo {
				width: 20%;
				max-width: 120px;
				margin-right: 80px;
			}

			// .footer-menu {
			// 	width: 25%;
			// 	margin-right: 30px;

			// 	ul {
			// 		padding: 0;
			// 		margin: 0;
			// 		list-style: none;

			// 		li {
			// 			border-bottom: 1px solid $color-brown;

			// 			a {
			// 				width: 100%;
			// 				padding: 10px 5px;
			// 				color: $color-text;
			// 				display: inline-block;
			// 			}

			// 			&:last-child {
			// 				border-bottom: none;
			// 			}

			// 			&:hover {

			// 				a {
			// 					padding-left: 15px;
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			.schedules {
				width: 25%;
				margin-right: 70px;
			}

			.contact-footer {
				margin-right: 70px;

				a {
					font-weight: 500;
					color: $color-text;
					vertical-align: middle;
					position: relative;
					margin-bottom: 12px;
					display: inline-block;

					&:hover {
						&::after {
							transform: scaleX(1);
							transform-origin: 0 50%;
						}
					}

					&::after {
						content: "";
						width: 100%;
						height: 2px;
						background-color: $color-text;
						position: absolute;
						left: 0;
						bottom: -4px;
						transform-origin: 100% 50%;
						transform: scaleX(0);
						transition: transform .2s linear;
					}

					&:not(.link) {

						&::before {
							font-family: $font-awesome;
							font-weight: 400;
							margin-right: 10px;
							vertical-align: middle;
							font-size: 1.1rem;
						}
					}


					&.phone {
						&::before {
							content: "\f095";
						}
					}
			
					&.mail {
						&::before {
							content: "\f0e0";
						}
					}
				}
			}

			.socials {

				a {
					font-size: 1.5rem;
					margin-right: .9rem;

					i {
						color: $color-text;
						width: auto !important;
					}
				}

				.logos {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: .5rem;
					flex-wrap: wrap;
					margin-top: 2rem;

					img {
						max-width: 3rem;
					}
				}
			}
		}
	}

	#footer-bottom {
		background-color: $color-text;
		color: white;
		font-size: 0.8rem;
		padding: 5px 0;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 400;
		}

		a {
			color: white;
		}

		.left {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.conditions,
			.policy {
				margin-left: 50px;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap;

			@media (max-width:1199px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
		}

		.collab {
			margin-right: 12px;
			margin-bottom: 4px;

			&::after {
				content: "|";
				margin-left: 12px;
			}

			@media (max-width:1199px) {
				margin-right: 0;

				&::after {
					display: none;
					visibility: hidden;
				}
			}
		}
	}

	.powered {
		position: relative;
		color: inherit;

		&:hover {
			color: inherit;
			text-decoration: none;

			#logo-alt-footer-trois {
				fill: #FFD94A;
				transform: scale(1.2);
			}

			#logo-alt-footer-deux {
				fill: #FFD94A;
				transform: scale(1.2);
			}

			#logo-alt-footer-un {
				fill: #FFD94A;
				transform: scale(1.2);
			}

			&:after {
				width: 105px;
			}
		}

		svg {
			overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;

			#logo-alt-footer-trois {
				fill: $color-text;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}

			#logo-alt-footer-deux {
				fill: $color-text;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}

			#logo-alt-footer-un {
				fill: $color-text;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 0px;
			height: 2px;
			background-color: #FFD94A;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}