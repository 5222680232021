//
// Responsive Styles under 576 pixels
// --------------------------------------------------

@media (max-width: 576px) {

  body {
    &.page-template-template-homepage {
      #page-header {
        height: calc(75vh - 100px);
        margin-top: 170px !important;

        .bg {

          svg {

            &.mobile {
              display: initial;
              visibility: visible;
            }

            &.desktop {
              display: none;
              visibility: hidden;
            }
          }
        }
      }
    }

    &.error404 {

      #page-header {
        margin-top: 180px !important;
      }
    }
  }

  #header {

    #logo {
      max-width: 90px;
    }
  }

  #side-menu {
    min-width: 90%;
    max-width: 100%;
    padding: 1.5rem 1.8rem;

    .mm-panels {
      z-index: -1;
    }
  }

  #booking-modal {
    padding-right: inherit;
    .modal-dialog {
      width: 100%;
      margin: 0 !important;
    }

    .modal-content {
      height: 100%;
      background: white;
      .modal-body {
        padding: 1.5rem 0.4rem;
      }
      .close {
        padding: inherit;
        width: 20%;
        position: relative;
        top: 20px;
        z-index: 1;
      }
    }
  }

  #main {

    #main-content {

      .team-row {
        padding: 0 15px;

        .members {
          grid-template-columns: 1fr !important;
          row-gap: 3.125rem;

          .member {
            margin: 0 !important;
          }
        }
      }
    }
  }
}