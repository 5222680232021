//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------
@font-face {
  font-family: 'Happy Camper';
  src: url('../webfonts/HappyCamper/Happy-Camper-Regular.eot');
  src: url('../webfonts/HappyCamper/Happy-Camper-Regular.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/HappyCamper/Happy-Camper-Regular.woff2') format('woff2'),
    url('../webfonts/HappyCamper/Happy-Camper-Regular.woff') format('woff'),
    url('../webfonts/HappyCamper/Happy-Camper-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}