//
// Responsive Styles
// --------------------------------------------------

@media (min-width: 1200px) {

	.container {
		max-width: 1170px;
	}

}

@media (min-width: 1441px) {

	.container {
		max-width: 1440px;
	}

}

@import "1441px";
@import "1199px";
@import "991px";
@import "767px";
@import "576px";