//
// Template Styles
// --------------------------------------------------
.shadow {
    box-shadow: 8px 6px 12px rgba(173, 164, 150, 0.6);
}

body {
    &.page-template-template-homepage {
        #page-header {
            margin-top: 220px !important;
            position: relative;
            max-width: 100% !important;
            height: calc(91vh - 220px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            ;

            .bg {
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: 100%;

                svg {
                    height: 100%;
                    width: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &.mobile {
                        display: none;
                        visibility: hidden;
                    }
                }
            }

            .illustration {
                width: 100%;
                max-width: 170px;
                height: auto;
                margin: 0 auto;
                margin-bottom: 20px;
                display: block;
            }

            #anim {
                width: 100%;
                max-width: 300px;
                height: auto;
                margin: 0 auto;
            }

            h1 {
                text-align: center;
                max-width: 50%;
                margin: 0 auto;
            }
        }
    }

    &.error404 {

        #page-header {
            margin-top: 230px !important;

            .container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .illustration {
                width: 80%;
                margin: 0 auto;
                margin-bottom: 10px;
                max-width: 250px;
            }

            h1 {
                text-align: center;
            }
        }
    }
}

.modal-backdrop {
    background-color: $color-primary;
    opacity: .4;
}

#booking-modal {

    .modal-dialog {
        width: 40%;
        max-width: 600px;
        margin: 1.75rem auto !important;
        min-height: calc(100% - 3.5rem) !important;
    }

    .modal-content {
        background-color: transparent;
        border: none;

        .close {
            background-color: transparent;
            color: $color-text;
            font-size: 1.5rem;
            border: none;
            padding: 0.9rem 0;
            width: fit-content;
            margin-left: auto;

        }

        .modal-body {
            background-color: white;
            padding: 1.6rem 6.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 100vh;
            max-height: 65vh;
            overflow-y: scroll;
        }
    }
}

#main {
    padding-top: 0; // Header height
    z-index: 15;

    #page-header {
        width: 100%;
        max-width: 2500px;
        margin: 0 auto;
        margin-bottom: 6.5rem;
    }

    #main-content {

        .event-bloc {
            padding: 10px;
            background-color: white;
            display: grid;
            grid-template-columns: 26rem auto;
            gap: 1.8rem;

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
            }

            .event-infos {
                padding: 1.8rem;
                display: flex;
                flex-direction: column;
            }

            .date {
                display: flex;
                align-items: center;
                gap: .7rem;
                margin-bottom: 1.5rem;
            }

            .btn-primary {
                margin-top: 2.7rem;
                margin-left: auto;
            }
        }

        .loader {
            display: block;
            width: fit-content;
            margin: 0 auto;

            .circle {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-color: $color-brown;
                border-radius: 50%;
                animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
                transform-origin: bottom center;
                position: relative;
                margin: 0 3px;
            }

            @keyframes loading {
                0% {
                    transform: translateY(0px);
                    background-color: $color-brown;
                }

                50% {
                    transform: translateY(-20px);
                    background-color: $color-primary;
                }

                100% {
                    transform: translateY(0px);
                    background-color: $color-brown;
                }
            }

            .circle-1 {
                animation-delay: 0.1s;
            }

            .circle-2 {
                animation-delay: 0.2s;
            }

            .circle-3 {
                animation-delay: 0.3s;
            }
        }

        .team-row {
            display: grid;
            grid-template-columns: 55% 45%;
            column-gap: 60px;
            margin-top: 5rem;

            &:first-of-type {
                margin-top: 0;
            }

            .members {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 45px;
                row-gap: 40px;

                .member {
                    margin: 0;

                    &:nth-child(odd) {
                        margin-top: 3.125rem;
                        margin-right: 1.1rem;
                        grid-column-start: -1;

                        .pola-img {
                            transform: rotate(-5.5deg);

                            &:hover {
                                transform: rotate(-4deg);
                            }
                        }
                    }

                    .pola-img {
                        margin-top: 1.25rem;

                        &:hover {
                            transform: rotate(1.5deg);
                        }
                    }
                }
            }

            &.right {
                grid-template-columns: 45% 55%;

                .members {
                    order: 2;

                    .member {
                        margin: 0;
                        grid-column-start: auto;

                        .pola-img {
                            transform: rotate(0deg) !important;

                            &:hover {
                                transform: rotate(1.5deg) !important;
                            }
                        }

                        &:nth-child(even) {
                            margin-top: 3.125rem;
                            margin-left: 1.1rem;
                            grid-column-start: -1;

                            .pola-img {
                                transform: rotate(5.5deg) !important;

                                &:hover {
                                    transform: rotate(4deg) !important;
                                }
                            }
                        }
                    }
                }

                .description {
                    order: 1;
                }

                .text {
                    order: 4;
                }

                .img-container {
                    order: 3;

                    .pola-img {
                        transform: rotate(-5.5deg);
                    }
                }
            }

            .description {
                align-self: center;
            }

            .btn-primary {
                margin-top: 5rem;
            }

            .img-container {
                margin-top: 4rem;
                align-self: center;
                order: 4;

                .pola-img {
                    max-width: 80%;
                    transform: rotate(5.5deg);
                }
            }

            .text {
                order: 3;
                align-self: center;
            }
        }

        #page-not-found {

            .error-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-evenly;
            }
        }

        #page-weather {
            #current-weather {
                padding: 2.2rem 2.8rem;
                background-color: rgb(255, 252, 241);
                margin-bottom: 1.6rem;
            }

            .simple-weather:not(.simple-weather--view-widget) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .simple-weather__day {

                    .simple-weather__date {
                        font-size: 1.3rem;
                        color: $color-text;
                        font-family: $font-base;
                        font-weight: 600;
                        line-height: $title-base-line-height;
                        margin-bottom: 0.6rem;
                        text-transform: capitalize;
                    }

                    .simple-weather__temp {
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                }
            }

            .simple-weather-widget {
                padding: 1.7rem;

                .temp,
                .summary {
                    display: none;
                    visibility: hidden;
                }

                .simple-weather-table__row {
                    &:first-of-type {
                        display: none;
                        visibility: hidden;
                    }

                    &:nth-of-type(2) {

                        &::before {
                            display: none;
                            visibility: hidden;
                        }
                    }

                    .simple-weather-table__date {
                        text-transform: capitalize;
                    }

                    .simple-weather-table__temp {
                        font-weight: 500;
                    }
                }
            }
        }

        #related-events,
        #events {
            margin-top: 6rem;

            .events {
                margin-top: 2.5rem;

                .grid {
                    display: flex;
                    flex-direction: column;
                    gap: 3.5rem;
                }
            }

            .event-bloc {
                filter: drop-shadow(8px 8px 10px rgba(173, 164, 150, 0.30));
            }
        }

        #single-event {
            margin-top: 1rem;
            
            .event-content {
                margin-top: 4.5rem;

                @media (max-width:991px) {
                    margin-top: 2rem;
                }
                
                .grid {
                    display: grid;
                    gap: 1rem;
                    grid-template-columns: 50% 43%;
                    justify-content: space-between;

                    @media (max-width:991px) {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 2rem;
                    }
                }

                .event-infos {
                    border: 4px dashed $color-brown;
                    padding: 2.5rem;
                    border-radius: .2rem;

                    @media (max-width:991px) {
                        padding: 1.5rem;
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        margin-top: 1.8rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;

                        li {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 1.5rem;
                            font-weight: 500;
                            color: black;

                            &::before {
                                display: none;
                            }

                            .icon {
                                width: 100%;
                                max-width: 48px;
                                height: auto;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }

            .share-post {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: .8rem;
                margin-top: 2.2rem;
            
                p {
                  font-size: .9rem;
                  margin: 0;
                  color: $color-primary;
                  font-weight: 500;
                }
            
                ul.social-share {
                  margin: 0;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: .7rem;
                  
                  li {
                    &::before {
                      display: none;
                    }
                  }
                }
            
            }
        }

        #page-homepage {

            #events {
                margin-bottom: 7rem;
                .events-slider {
                    filter: drop-shadow(8px 8px 10px rgba(173, 164, 150, 0.30));
                    
                    &.slick-initialized {
                        margin-inline: auto;
                        width: calc(100% - 90px);
                    }

                    .slick-dots {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 7px;
                        position: static;
                        margin-top: 1rem;

                        li {
                            &::before {
                                display: none;
                            }

                            button {
                                background-color: $color-brown !important;
                                opacity: .5 !important;
                            }
                        }
                    }

                    .slick-arrow {
                        opacity: 1;
                        background-color: $color-brown;
                        width: 35px !important;
                        height: 35px !important;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 1;

                        &::before {
                            font-size: 18px;
                            line-height: 1;
                            font-weight: 400;
                        }

                        &.slick-prev {
                            left: -45px;
                        }

                        &.slick-next {
                            right: -45px;
                        }
                    }
                }
            }

            .intro {
                padding-bottom: 11rem;
                position: relative;

                .illustration {
                    position: absolute;
                    left: -6%;
                    bottom: 12%;
                    width: 11%;
                }

                h2 {
                    position: relative;
                    z-index: 1;
                    margin-bottom: 0;
                }

                .content {
                    display: grid;
                    grid-template-columns: 65% 35%;
                    column-gap: 50px;
                    padding-left: 50px;
                    padding-right: 30px;
                    margin-top: -50px;
                }

                .description {
                    margin-top: 40px;
                    margin-left: 50px;
                    margin-right: 50px;
                }

                .slogan {
                    font-size: 4rem;
                    color: $color-brown;
                    margin-top: 1.9rem;
                    margin-left: 50px;
                    position: relative;
                    width: fit-content;
                    padding: 0 3.9rem;

                    &::before,
                    &::after {
                        font-family: $font-awesome;
                        font-weight: bold;
                        font-size: 3.2rem;
                        position: absolute;
                    }

                    &::before {
                        content: "\f10d";
                        left: 0;
                        top: -30px;
                    }

                    &::after {
                        content: "\f10e";
                        right: 0;
                        bottom: -30px;
                    }
                }

                .blocs {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .wprs_unslider {
                        margin-top: auto;
                    }

                    .bloc {
                        margin-bottom: 2.2rem;

                        .subtitle {
                            margin-bottom: 5px;
                            display: inline-block;
                            font-size: 1.1rem;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .tripadvisor {
                        position: relative;
                        width: fit-content;
                        margin: 0 auto;
                        margin-top: 5rem;

                        .arrow {
                            position: absolute;
                            top: 3.5rem;
                            right: -45px;
                            max-width: 30px;
                            transform: rotate(18deg);
                        }

                        .badge {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            padding: 65px 25px;
                            border-radius: 50%;
                            width: 100%;
                            max-width: 230px;
                            max-height: 230px;
                            aspect-ratio: 1;
                            color: $color-text;
                            text-align: center;
                            background-color: $color-brown;
                            box-shadow: 0px 6px 12px 4px rgba(173, 164, 150, 0.17);
                            transform-origin: center;

                            .logo {
                                max-width: 120px;
                                margin-top: 0.6rem;
                            }

                            &:hover {
                                background-color: darken($color-brown, 8%);
                                box-shadow: 0px 6px 12px 0px rgba(173, 164, 150, 0.17);
                            }
                        }
                    }
                }
            }

            .place {
                padding-top: 180px;
                position: relative;
                margin-bottom: 10rem;

                .illustration {
                    position: absolute;
                    top: -12%;
                    left: 0;
                    width: 30%;
                }

                h2 {
                    text-align: center;
                }

                .tabs-content {
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                    column-gap: 15px;

                    #pills-tab {
                        grid-column: 1 / 3;
                        flex-direction: column;
                        justify-content: flex-end;
                        margin-top: 0;
                        padding-bottom: 60px;

                        li {
                            margin-top: 1.25rem;
                            margin-bottom: 0;
                            display: flex;
                            align-items: center;
                            transition: $transition-base;

                            svg {
                                width: auto;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: -1;
                                transition: $transition-base;

                                path {
                                    transition: $transition-base;
                                }
                            }

                            &::before {
                                display: none;
                            }

                            a {
                                padding: 12px 25px;
                                display: inline-block;
                                color: $color-text;
                                position: relative;

                                &.active {
                                    margin-left: 15px;
                                    font-weight: 700;

                                    svg {
                                        path {
                                            fill: $color-yellow;
                                        }
                                    }
                                }
                            }

                            &:hover {

                                svg {

                                    path {
                                        fill: $color-primary;
                                        box-shadow: 6px 6px 8px rgba(126, 119, 108, 0.6);
                                    }
                                }
                            }
                        }
                    }

                    #pills-tabContent {
                        grid-column: 3 / 13;
                    }

                    .tab-pane {
                        height: 0;
                        display: block;
                        overflow: hidden;

                        &.active {
                            height: auto;
                        }

                        .gallery {
                            width: 100% !important;
                            padding: 25px;

                            .pola-img {
                                width: 100% !important;
                                height: auto;
                            }
                        }
                    }
                }

                .description {
                    margin-top: 1.25rem;
                    padding: 0 25px;
                    margin-left: 16%;
                }
            }

            .parchment {
                position: relative;
                padding: 10rem 0;

                svg {
                    position: absolute;
                    left: 0%;
                    top: 50%;
                    transform: translate(0%, -50%);
                    z-index: -1;
                    height: 100%;
                    width: auto;
                }

                .content {
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                    column-gap: 1.9rem;

                    .icon {
                        grid-column: 2 / 4;
                        height: auto;
                    }

                    .text {
                        grid-column: 4 / 12;
                    }

                    .subtitle {
                        margin-bottom: 5px;
                        display: inline-block;
                        font-size: 1.1rem;
                    }

                    ul {
                        margin-top: 3.1rem;
                        margin-bottom: 0;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        column-gap: 15px;
                        row-gap: 15px;

                        li {
                            &::before {
                                display: none;
                            }

                            a {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .about {
                position: relative;
                padding-top: 16rem;

                .illustration {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    max-width: 35%;
                    width: auto;
                    height: 15rem;
                }
            }
        }

        #page-menu {

            .menu {

                .select-menu {
                    padding: 10px 15px;
                    border-bottom: 6px solid $color-brown;
                    color: $color-text;
                    align-items: center;
                    justify-content: space-between;
                    transition: $transition-base;
                    font-size: 1.1rem;
                    display: none;

                    span {
                        width: 100%;
                        display: inline-block;
                    }

                    i {
                        transform: rotate(0deg);
                        transition: $transition-base;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &.is-open {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        z-index: 1;

                        i {
                            transform: rotate(180deg);
                        }
                    }
                }

                #pills-tab {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    li {
                        margin: 0 50px;
                        margin-bottom: 30px;

                        &::before {
                            visibility: hidden;
                            display: none;
                        }

                        .illustration {
                            height: 150px;
                            width: auto;
                            margin-bottom: 25px;
                        }

                        a {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;

                            &.active {

                                .btn-primary {

                                    &::before,
                                    &::after {
                                        display: initial;
                                    }
                                }
                            }
                        }

                        .btn-primary {
                            font-weight: 600;

                            &::before,
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                #pills-tabContent {
                    margin-top: 6rem;

                    section {
                        margin-bottom: 5rem;

                        h4 {
                            margin-bottom: 2rem;
                        }

                        ul {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            column-gap: 30px;
                            row-gap: 0;

                            li {
                                border-bottom: 1px solid $color-primary;
                                padding: 15px 10px;
                                margin: 0;

                                &:last-of-type,
                                &:nth-last-child(2):nth-child(odd) {
                                    border-bottom: none;
                                }

                                &::before {
                                    display: none;
                                }

                                h6 {
                                    margin-bottom: 0.5rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                }

                                .ingredients {
                                    font-size: 0.9rem;
                                    color: $color-primary;
                                }

                                .price {
                                    white-space: nowrap;

                                    &::before {
                                        content: "CHF ";
                                        font-size: 0.9rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #page-contact {

            .contact {
                display: grid;
                grid-template-columns: 30% 70%;
                column-gap: 30px;
            }

            .access {
                margin-bottom: 3.5rem;

                address {
                    margin-bottom: 1.2rem;
                }
            }

            .btn-booking {
                background-color: transparent;
                margin-top: 1.2rem;
            }

            .infos {
                margin-top: 6rem;
            }
        }

        #page-about {

            .jobs {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 8rem;

                .illustration {
                    margin-bottom: 1.5rem;
                    width: 80%;
                    max-width: 250px;
                }

                h2 {
                    font-size: 4rem;
                    text-align: center;
                }

                .content {
                    width: 100%;
                    max-width: 65%;
                    padding: 0;
                    margin: 0;
                    margin-top: 2.8rem;

                    li {
                        border-bottom: 1px solid $color-primary;

                        &::before {
                            display: none;
                            visibility: hidden;
                        }

                        a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 30px 10px;
                        }

                        h4 {
                            transition: $transition-base;
                        }

                        &:hover {

                            h4 {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }

        #page-place {

            .intro {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                margin-top: 10rem;

                .text {
                    width: 60%;
                    position: relative;
                    bottom: 0;
                    left: 0;
                    margin-bottom: 6.5rem;

                    .caption {
                        font-size: 1.2rem;
                        color: $color-text;
                        font-family: $font-base;
                        font-weight: 800;
                        margin-bottom: 1rem;
                    }

                    .description {
                        width: 65%;
                    }
                }

                .img-container {
                    position: relative;
                    // right: 0;
                    // bottom: 0;
                    width: 65%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    .illustration {
                        position: absolute;
                        top: -6rem;
                        right: 45%;
                        z-index: 11;
                    }

                    .pola-img {
                        position: relative;
                        bottom: 0;

                        &.left {
                            width: 50%;
                            left: 3rem;
                            bottom: 2rem;
                        }

                        &.right {
                            width: 62%;
                            right: 0;
                            z-index: 1;
                        }
                    }
                }
            }

            .blocs {
                display: grid;
                grid-template-columns: 42% 42%;
                column-gap: 5.5rem;
                row-gap: 5rem;
                margin-top: 8rem;
                position: relative;

                .illustration {
                    position: absolute;
                    left: 20%;
                    top: -3rem;
                }


                .bloc {

                    .subtitle {
                        font-size: 1.1rem;
                        margin-bottom: 0.3rem;
                        display: block;
                        width: 45%;
                    }
                }
            }

            .gform_wrapper {

                #field_4_6,
                #field_4_8 {

                    .gfield_label {
                        top: 45px;
                    }

                    .gfield_description {
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }

                #input_4_6,
                #input_4_8 {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 25px;

                    .ginput_container_date {
                        position: relative;
                    }
                }
            }

            .blocs-img {
                margin-top: 10rem;

                .bloc-img {
                    width: 80%;
                    margin-top: 6rem;
                    margin-left: 1.5rem;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:nth-of-type(even) {
                        margin-left: auto;
                        margin-right: 1.5rem;

                        h2 {
                            margin-right: -1.5rem;
                            margin-left: auto;
                            margin-bottom: -60px;
                        }

                        .img-container {

                            .illustration {
                                right: initial;
                                left: -20%;
                            }
                        }
                    }

                    h2 {
                        width: fit-content;
                        position: relative;
                        z-index: 1;
                        margin-left: -1.5rem;
                        margin-bottom: -50px;
                    }

                    .img-container {
                        position: relative;

                        .illustration {
                            position: absolute;
                            bottom: 5%;
                            right: -20%;
                            width: 25%;
                            z-index: 1;
                        }
                    }

                    .pola-img {
                        margin-bottom: 3.5rem;
                    }
                }
            }

            .infos {
                margin-top: 10rem;

                h2 {
                    text-align: center;
                    margin-bottom: 3.9rem;
                }

                .content {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 2.5rem;
                    row-gap: 2rem;
                    align-items: flex-end;
                }

                .element {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        margin-bottom: 1rem;
                        height: auto;
                        max-height: 115px;
                        width: auto;
                    }

                    h3 {
                        font-size: 1.3rem;
                        color: $color-text;
                        font-family: $font-base;
                        font-weight: 600;
                        line-height: $title-base-line-height;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}