//
// Responsive Styles under 767 pixels
// --------------------------------------------------

@media (max-width: 767px) {

  body,
  html {
    font-size: 16px;
    line-height: 1.5em;
  }

  h1 {
    font-size: 4.4rem;
  }

  h2 {
    font-size: 3.75rem;
  }

  h3 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1.125rem;
  }

  body {
    &.page-template-template-homepage {

      #page-header {

        .illustration {
          max-width: 140px;
        }

        #anim {
          max-width: 300px;
          height: auto;
        }

        h1 {
          max-width: 95%;
        }
      }
    }
  }

  form {

    .gform_fields {
      grid-template-columns: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  #header {

    #header-extras {

      #meteo {

        .simple-weather__day:not(.simple-weather__day--current) {
          display: none;
          visibility: hidden;
        }

        .simple-weather__date {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  #side-menu {
    min-width: 50%;
    max-width: 80%;

    .signature {
      display: none;
      visibility: hidden;
    }
  }

  #booking-modal {

    .modal-dialog {
      width: 75%;
    }
  }

  #main {

    #main-content {

      .team-row {

        .btn-primary {
          width: 100%;
        }

        .img-container {

          .pola-img {
            max-width: 100%;
          }
        }
      }

      #page-weather {

        .simple-weather:not(.simple-weather--view-widget) {
          grid-template-columns: 100%;
          row-gap: 3rem;
        }
      }

      #page-menu {

        .menu {

          #pills-tabContent {

            section {

              h4 {
                margin-bottom: 1rem;
              }

              ul {
                grid-template-columns: 100%;

                li {
                  &:nth-last-child(2):nth-child(odd) {
                    border-bottom: 1px solid $color-primary;
                  }

                  .price {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }

      #page-place {

        .intro {

          .text {

            .description {
              width: 100%;
            }
          }

          .img-container {

            .illustration {
              max-width: 60%;
            }
          }
        }

        .blocs-img {

          .bloc-img {

            .pola-img {
              margin-bottom: 7rem;
            }

            h2 {
              margin-bottom: -30px;
            }

            .img-container {

              .illustration {
                width: 40%;
              }
            }

            &:nth-of-type(even) {

              h2 {
                margin-bottom: -40px;
              }
            }
          }
        }
      }

      #page-contact {

        .contact {

          .infos {

            .btn-primary {
              width: 100%;
            }
          }
        }
      }

      #page-homepage {

        .intro {

          .content {
            padding-left: 25px;
          }

          .slogan {
            font-size: 2.9rem;
            padding: 0 2.9rem;
          }
        }

        .about {

          .illustration {
            max-width: 72%;
          }
        }
      }

      #page-about {

        .jobs {

          .content {
            max-width: 100%;

            li {

              a {
                flex-wrap: wrap;

                .btn-secondary {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  #footer {
    margin-top: 100px;

    #footer-top {
      padding-top: 60px;

      .container {
        flex-direction: column;
        justify-content: flex-start;

        *:not(a) {
          width: 100% !important;
        }

        .contact-footer {
          margin-top: 40px;
        }

        .schedules {
          margin: 0;
          margin-top: 50px;
        }
      }
    }
  }

  #wpadminbar {
    position: fixed !important;
  }
}