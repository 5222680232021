//
// Responsive Styles under 1199 pixels
// --------------------------------------------------

@media (max-width: 1199px) {

  body {
    &.page-template-template-homepage {

      #page-header {

        h1 {
          max-width: 60%;
        }
      }
    }
  }

  #header {
    #menu-mobile-trigger {
      display: block;
    }
  }

  #side-menu {
    min-width: 30%;
    max-width: 45%;
  }

  #booking-modal {

    .modal-dialog {
      width: 45%;
    }

    .modal-content {

      .modal-body {
        padding: 1.5rem;
      }
    }
  }

  #main {

    #main-content {

      .team-row {
        grid-template-columns: 1fr !important;

        .description {
          margin-top: 4rem;
          order: 2 !important;
        }

        .btn-primary {
          margin-top: 3rem;
        }

        .members {
          order: 1 !important;

          .member {
            &:nth-child(odd) {

              .pola-img {
                transform: rotate(-5.5deg) !important;
              }
            }

            &:nth-child(even) {

              .pola-img {
                transform: rotate(5.5deg) !important;
              }
            }
          }
        }

        .img-container {
          order: 3 !important;
          margin-bottom: 3.5rem;
        }

        .text {
          order: 4 !important;
        }
      }

      #page-place {

        .blocs-img {

          .bloc-img {
            width: 85%;
          }
        }
      }

      #page-homepage {

        .intro {

          .content {
            grid-template-columns: 60% 40%;
          }

          .description {
            margin-left: 20px;
            margin-right: 20px;
          }

          .illustration {
            width: 15%;
            bottom: 15%;
          }

          #anim {
            width: 20%;
            height: auto;
          }
        }

        .place {
          margin-bottom: 8rem;

          .tabs-content {

            #pills-tab {
              grid-column: 1 / 4;
            }

            #pills-tabContent {
              grid-column: 4 / 13;
            }
          }

          .description {
            margin-left: 25%;
          }
        }

        .parchment {
          padding: 8rem 0;

          .content {
            display: block;
            padding-left: 6rem;

            .icon {
              width: 100%;
              max-width: 9rem;
              margin-bottom: 1.4rem;
            }
          }
        }

        .about {

          .illustration {
            max-width: 50%;
          }
        }
      }

      #page-about {

        .jobs {

          .content {
            max-width: 95%;
          }
        }
      }
    }
  }

  #footer {

    .signature,
    .socials {
      margin-top: 2.3rem;
    }

    #footer-top {

      .container {
        flex-wrap: wrap;
        justify-content: space-between;

        .logo {
          margin-right: 0;
          width: 33%;
        }

        .schedules {
          margin: 0 50px;
          width: 33%;
        }

        .contact-footer {
          margin-right: 0;
          width: 33%;
        }
      }
    }
  }
}