//
// Responsive Styles under 991 pixels
// --------------------------------------------------

@media (max-width: 991px) {

  body {
    &.page-template-template-homepage {

      #page-header {
        height: calc(80vh - 220px);

        h1 {
          max-width: 75%;
        }
      }
    }
  }

  #header {

    #header-extras {

      #meteo {

        .simple-weather__day:last-child {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  #side-menu {
    min-width: 45%;
    max-width: 70%;
    padding: 1.5rem 3rem;

    #menu-secondaire {
      margin-top: 0;
    }
  }

  #booking-modal {

    .modal-dialog {
      width: 55%;
    }
  }

  .acf-map {
    height: 350px;
  }

  #main {

    #main-content {

      #page-menu {

        .menu {
          position: relative;

          .select-menu {
            display: flex;
          }

          #pills-tab {
            display: none;
            margin-top: 0;
            position: absolute;
            left: 0;
            top: 53px;
            width: 100%;
            border: 6px solid $color-brown;
            border-top: none;
            background-color: $color-beige;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &.is-open {
              display: block;
            }

            li {
              margin: 0;
              border-bottom: 6px solid $color-brown;

              &:last-of-type {
                border-bottom: none;
              }

              a {
                justify-content: center;
                align-items: flex-start;
                padding: 10px 15px;
              }

              .btn-primary {
                padding: 0;
                margin: 0;
                font-weight: normal;
                font-size: 1.1rem;

                &::before,
                &::after {
                  display: none;
                  visibility: hidden;
                }
              }

              .illustration {
                display: none;
                visibility: hidden;
              }
            }
          }
        }
      }

      #page-place {

        .intro {
          display: block;
          margin-top: 8rem;

          .text {
            width: 100%;
            margin-bottom: 7.5rem;

            .description {
              width: 80%;
            }
          }

          .img-container {
            width: 100%;
          }
        }

        .blocs {
          grid-template-columns: 100%;
          row-gap: 3.7rem;

          .illustration {
            display: none;
            visibility: hidden;
          }
        }

        .blocs-img {
          margin-top: 8rem;

          .bloc-img {
            width: calc(100% - 1.5rem);

            .img-container {

              .illustration {
                right: -10%;
              }
            }

            &:nth-of-type(even) {

              .img-container {

                .illustration {
                  left: -10%;
                }
              }
            }
          }
        }

        .infos {
          margin-top: 8rem;

          .content {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      #page-contact {

        .contact {
          grid-template-columns: 100%;

          .infos {
            margin-top: 0;
            margin-bottom: 3.8rem;
          }
        }
      }

      #page-homepage {

        .intro {
          padding-bottom: 8rem;

          .illustration {
            display: none;
            visibility: hidden;
          }

          .content {
            grid-template-columns: 100%;
            padding-right: 0;
          }

          .description {
            margin-left: 0;
            margin-right: 0;
          }

          .slogan {
            margin-left: 0;
            margin-bottom: 2rem;
            padding: 0 3.2rem;
          }
        }

        .place {
          padding-top: 0;

          .illustration {
            display: none;
            visibility: hidden;
            padding-top: 0;
          }

          .tabs-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            #pills-tab {
              width: 100%;
              padding-bottom: 0;
              order: 2;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              li {
                width: 50%;
                margin: 0;

                a {
                  margin: 0 auto;

                  &.active {
                    margin-left: auto;
                  }
                }
              }
            }

            #pills-tabContent {
              width: 100%;
              order: 1;
            }
          }

          .description {
            margin-left: 0;
            margin-top: 2.5rem;
            padding: 0;
          }
        }

        .parchment {
          padding: 6rem 15px;

          svg {
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .content {
            padding-left: 0;

            ul {
              grid-template-columns: 100%;
            }
          }
        }

        .about {
          padding-top: 15rem;

          .illustration {
            max-width: 65%;
          }
        }
      }
    }
  }

  #back-to-top {
    bottom: 18%;
  }

  #footer {

    #footer-bottom {
      padding: 20px 0;

      .container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        a {
          margin-left: 0 !important;
          width: 100%;
          padding: 3px 0;
          border-bottom: 1px solid white;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            padding-left: 5px;
          }
        }
      }

      .copyright {
        margin-bottom: 15px;
      }

      .collab {
        margin-top: 20px;
      }

      .troisdeuxun-powered {
        margin-top: 12px;
      }
    }
  }
}