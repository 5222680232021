//
// Responsive Styles under 1441 pixels
// --------------------------------------------------

@media (max-width: 1441px) {

  #booking-modal {

    .modal-dialog {
      width: 50%;
    }
  }
}