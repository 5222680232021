//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: transparent;
	padding: 25px 0;
	padding-bottom: 15px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;

	&.sticky {
		padding: 15px 0;
		padding-bottom: 25px;
		background-color: $color-beige;
		box-shadow: 8px 0px 15px rgba(173, 164, 150, 0.5);

		#logo {

			img {
				&.logo-sticky {
					display: block;
					opacity: 1;
				}

				&.logo {
					display: none;
					opacity: 0;
				}
			}
		}

		#menu {
			margin-top: 50px;
		}

		#header-extras {

			.extra {
				&#meteo,
				&#language-switcher {
					opacity: 0;
					display: none;
				}

				&#socials {
					margin-right: 0;
				}
			}
		}
	}

	#menu-mobile-trigger {
		display: inline-block;
		margin-left: 50px;

		.trigger-bar {
			display: block;
			width: 35px;
			height: 3px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-text;
			transition: $transition-base;

			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		&:hover {

			.trigger-bar {

				&:nth-child(2) {
					width: 30px;
				}
			}
		}
	}

	#logo {
		float: left;
		width: 100%;
		max-width: 115px;

		a {
			display: block;
		}

		img {
			transition: $transition-base;

			&.logo-sticky {
				display: none;
				opacity: 0;
			}
		}
	}

	#search-action {
		.input-group {
			display: flex;

			.form-control {
				width: 250px;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#menu {
		transition: $transition-base;
		float: right;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.btn-booking {
			background-color: transparent;
			margin-left: 50px;
			font-size: 1rem;
			font-family: inherit;
			border: 2px solid $color-text;
			background-color: $color-text;
			color: $color-beige !important;

			&::before,
			&::after {
				display: none;
				visibility: hidden;
			}

			&:hover {
				border: 2px solid $color-text !important;
				background-color: $color-beige !important;
				color: $color-text !important;
			}
		}

		#menu-main-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				li {
					float: left;
					position: relative;
					margin-right: 50px;

					a {
						font-size: 1.3rem;
						font-weight: 600;
						display: block;
						color: $color-text;
						position: relative;

						&::before {
							content: "";
							position: absolute;
							left: 0;
							bottom: -8px;
							width: 100%;
							height: 2px;
							transform-origin: 100% 50%;
							transform: scaleX(0);
							background-color: $color-text;
							transition: transform .2s linear;
						}

						&:hover {
							color: $color-link-hover;

							&::before {
								transform: scaleX(1);
								transform-origin: 0% 50%;
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: $color-text;
							font-weight: 800;
						}

						.sub-menu {
							li {
								a {
									color: $color-text;
								}
							}
						}
					}

					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -30px;
						transform: scale(0.95) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;

							a {
								display: block;
								color: $color-text;
								background-color: $color-gray-darker;
								padding: 20px 35px;

								&:hover {
									color: $color-link-hover;
									background-color: lighten($color-gray-darker, 3%);
								}
							}

							&:last-child {
								margin-bottom: 0;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-text;
								}
							}
						}
					}

					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f105';
							display: block;
							position: absolute;
							top: 50%;
							right: -12px;
							font-weight: 100;
							transform: translateY(-50%);
							transform-origin: center;
							color: white;
							transition: transform 0.25s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
								padding-top: 27px;
							}
						}
					}
				}
			}
		}
	}

	#header-extras {
		display: flex;
		position: absolute;
		top: 0;
		right: 15px;
		transition: all 0.4s linear;
		margin-top: 0;

		.extra {
			display: inline-block;
			margin-right: 25px;

			&:last-child {
				margin-right: 0;
			}

			a {

				i {
					color: $color-text;
				}
			}

			&#socials {

				a {
					font-size: 1.2rem;
				}

				a:not(:last-child) {
					margin-right: 0.7rem;
				}
			}

			&#meteo {
				color: $color-text;
				font-weight: 500;
				font-size: 0.9rem;
				transition: $transition-base;

				// .simple-weather__day:not(.simple-weather__day--current) {
				// 	display: none;
				// 	visibility: hidden;
				// }

				// .simple-weather__date {
				// 	display: none;
				// 	visibility: hidden;
				// }
			}
		}

		#language-switcher {
			padding: 0;
			margin-top: 0;
			margin-bottom: 0;
			list-style: none;
			font-size: 14px;
			color: $color-primary;
			position: relative;
			background-color: transparent;
			transition: $transition-base;
			overflow: hidden;

			&:hover {
				overflow: visible;

				.lang-menu {
					height: auto;
					opacity: 1;
				}

				.lang {

					i {
						transform: rotate(180deg);
					}
				}
			}

			li {
				&::before {
					display: none;
				}
			}

			.lang-menu {
				opacity: 0;
				height: 0;
				width: 100%;
				transition: $transition-base;
				position: absolute;
				left: 0;
				padding-top: 5px;
				background-color: $color-beige;
			}

			.lang {
				text-transform: uppercase;
				font-size: 0.9rem;
				color: $color-text;
				font-weight: 500;

				&:hover {
					color: $color-link-hover;
				}

				&.active {
					cursor: pointer;

					i {
						margin-left: 4px;
					}
				}

				i {
					transform: rotate(0deg);
					transition: $transition-base;
					font-size: 0.8rem;
				}
			}
		}
	}
}

#side-menu {
	background-color: $color-beige;
	padding: 1.7rem 4.5rem;
	cursor: initial;
	box-shadow: 8px 0px 15px 5px rgba(173, 164, 150, 0.3);
	display: none;
	width: 100%;
	min-width: 25%;
	max-width: 40%;


	&.mm-menu_opened {
		display: initial;
	}

	&::before {
		display: none;
		visibility: hidden;
	}

	#close-menu {
		font-size: 1.8rem;
		color: $color-text;
		position: absolute;
		top: 1.7rem;
		right: 1.7rem;
		cursor: pointer;
		padding: 0;
		background-color: transparent;
		transform-origin: center;
	}

	.mm-navbar {
		display: none;
		visibility: hidden;
	}

	.mm-panels {
		background: none !important;

		>.mm-panel:not(.signature) {
			overflow: hidden;
			background: none !important;
			position: relative !important;
		}
	}

	.signature {
		margin-top: 100px;
		display: flex !important;
		position: static;
		transform: translate(0, 0);
		background: none;
		position: absolute;
		left: 0;
		right: initial;
		top: initial;
		bottom: 0;
	}

	#menu-principal {

		li {
			a {
				position: relative;
			}
		}
	}

	.menu-container,
	#menu-principal {
		position: static;
		height: 100%;
		width: 100%;
		padding: 0;
		top: 0;
		margin-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow: visible;

		&:first-of-type {
			margin-top: 100px;
		}

		&::after {
			display: none;
		}

		&.sub-menu {
			top: 50px;

			li {

				a {
					font-size: 1rem;
					padding: 0;
				}
			}
		}

		&.mm-spn--parent {
			left: -100%;
		}

		&::before {
			display: none;
			visibility: hidden;
		}

		li {
			margin-bottom: 1.7rem;
			width: fit-content;

			&:last-child {
				margin-bottom: 0;
			}

			&::after {
				display: none;
				visibility: hidden;
			}

			&:last-of-type {
				&::after {
					display: none;
					visibility: hidden;
				}
			}

			&::before {
				border-color: $color-text;
				opacity: 1;
				top: 50%;
			}

			&.current-menu-item,
			&.current_page_item {

				a {
					&::before {
						transform: scaleX(1);
						transform-origin: 0 50%;
					}
				}
			}

			&.menu-item-has-children {

				a {
					width: fit-content;
				}
			}

			&:not(.menu-item-has-children) {
				&::before {
					display: none;
					visibility: hidden;
				}
			}

			a {
				font-size: 1.3rem;
				font-family: $font-base;
				font-weight: 600;
				color: $color-text;
				line-height: $title-base-line-height;
				padding: 0;
				width: fit-content;

				&::after {
					display: none;
					visibility: hidden;
				}

				&:hover {
					color: darken($color-text, 6%);

					&::before {
						transform: scaleX(1);
						transform-origin: 0 50%;
					}
				}

				&::before {
					content: "";
					width: 100%;
					height: 2px;
					background-color: $color-text;
					position: absolute;
					left: 0;
					bottom: -8px;
					transform-origin: 100% 50%;
					transform: scaleX(0);
					transition: transform .2s linear;
				}
			}
		}

		.languages-list {
			margin-top: 30px;

			.lang {
				width: fit-content;
				margin-left: auto;
				text-transform: uppercase;
				display: block;
				color: $color-primary;
				font-weight: bold;

				&.active {
					color: $color-primary;
				}

				i {
					display: none;
					visibility: hidden;
				}
			}

			.lang-menu {
				margin-left: auto;
				width: fit-content;
			}
		}
	}
}